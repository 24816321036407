@media screen and (min-width: 1369px) and (max-width: 1599px) {
  .third-section,
  .first-section{
    background-size: 48% auto;
  }
}

@media screen and (min-width:  1368px) and (max-width: 1440px) {
  .login-left, .login-right {
    font-size:  14px;
  }
}

@media screen and (max-width: 1368px) {
  .people-thumb img {
    width: 100%;
    height: 100%;
  }
  .first-section, .second-section, .third-section {
    background-size: 50% auto;
  }
  .login-form {
    padding: 20px;
    width: 65%;
  }
  .user-choose {
    width: 65%;
  }
  .login-page.fit-content {
    .login-form, .user-choose {
      width: 36%;
    }
  }

  .smt-list .smtlist-item.second-line {
    min-width: auto;
  }


  .plan-description .smt-list:nth-of-type(1) .smtlist-item.first-line {
    min-width: 42%;
  }

}

@media screen and (min-width: 1025px) and (max-width: 1364px) {
  .people-thumb {
    height: auto;
  }
  .highlight-title h3 {
    font-size: 30px;
  }

  .first-section,
  .second-section,
  .third-section {
    height: 480px;
  }

  .second-section {
    height: 375px;
  }

  .select2-container {
    width: 100%;
  }

}

@media screen and (max-width: 1280px) {
  .people-related .smt-list.half .smt-half {
    width: 100%;
    margin-bottom: 20px;
  }

  .plan-description .smt-list:nth-of-type(1) .smtlist-item.first-line {
    min-width: 52%;
  }

  .plan-description .smtlist-item {
    padding: 0 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1279px) {
  .main-nav .navbar-nav > li > a {
    padding: 0 15px;
  }
  .tagline-content h1 {
    line-height: normal;
    line-height: initial;
  }
  .chart-legend .box-count {
    width: 35px;
    height: 35px;
    font-size: 14px;
    line-height: 35px;
  }
  #smtChart {
    margin: 0;
    width: 200px !important;
    height: 200px !important;
  }
  .chart-legend .box-count {
    width: 30px;
    height: 30px;
    font-size: 12px;
    line-height: 30px;
  }


}

@media screen and (max-width: 1024px) {
  .tagline-content h1 {
    font-size: 48px;
  }
  .action-description {
    font-size: 14px;
  }
  .center-tagline h4 {
    font-size: 16px;
  }
  .people-thumb {
    height: auto;
  }
  .highlight-title {
    h3 {
      font-size: 160%;
    }
    h4 {
      font-size: 90%;
    }
  }
  .bottom-inner h3 {
    font-size: 135%;
  }
  .middle-inner .smt-list {
    max-width: 90%;
  }
  .box-center .box-entry h4 {
    font-size: 100%;
  }
  .main-footer h3 {
    font-size: 125%;
  }
  .bottom-section .center-tagline {
    margin: 0 auto 35px;
  }
  .login-form, .user-choose {
    width: 75%;
  }
  .login-page.fit-content {
    .login-form, .user-choose {
      width: 45%;
    }
  }
  .chart-holder {
    width: 100%;
  }
  #smtChart {
    margin: 0 auto;
    width: 180px !important;
    height: 180px !important;
  }
  .election-geo h3 {
    margin-top: 20px;
  }
  .chart-legend {
    .box-count {
      display: block;
      margin: 0 auto;
    }
    p {
      display: block;
      text-align: center;
      margin-top: 10px;
    }
  }
  .center-tagline h3 {
    margin-top: 20px;
  }
  .main-nav .navbar-nav {
    > li > a {
      padding: 0 10px;
    }
    a.active {
      background: transparent;
      color: #e4002b;
    }
    > li > a.active {
      color: #e4002b;
    }
  }
}

@media screen and (max-width: 1023px) {
  .main-nav .navbar-nav > li > a {
    padding: 0px 6px;
    font-size: 14px;
  }
  .home-action .action-button {
    display: block;
    float: none;
    margin: 10px auto 0;
    text-align: center;
  }
  .action-description {
    text-align: center;
  }
  .feat-box {
    &.half-of-one .feat-box-item {
      width: 100%;
    }
    .feat-box-item {
      display: block;
    }
  }

  .contact-form .btn.btn-default {
    margin-bottom: 20px;
  }

  .plan-description .smt-list:nth-of-type(1) .smtlist-item.first-line {
    min-width: 58%;
  }

  .plan-description .smtlist-item {
    padding: 0;
  }


}

@media screen and (min-width: 801px)and (max-width: 1198px) {
  .search-form input[type="text"] {
    width: 86%;
  }
}

@media screen and (min-width: 801px)and (max-width: 1000px) {
  .search-form input[type="text"] {
    width: 82%;
  }
}

@media screen and (max-width: 990px) and (min-width: 768px) {
  .detail-city {
    .top-detail, .country-map {
      min-width: 100%;
    }
  }
  .feat-insider .nav-tabs {
    li {
      width: 20%;
      min-width: auto;
    }
    > li {
      > a, &.active {
        min-height: 130px;
      }
    }
  }
  .action-description {
    line-height: inherit;
  }

  .plan-description .smt-list:nth-of-type(1) .plan-description .smtlist-item {
    padding: 0 10px;
  }
}

@media screen and (max-width: 980px) {
  .main-nav .nav.navbar-nav {
    float: none;
  }
  .main-tagline {
    height: 100%;
    max-width: 90%;
  }
  .feature {
    height: auto;
  }
  .tagline-content {
    padding: 20% 0;
  }
  .feat-insider .nav-tabs li {
    min-width: auto;
  }
  .action-description {
    line-height: normal;
    line-height: initial;
  }
  .main-nav .navbar-nav > li > a {
    padding: 0px 15px;
    line-height: 48px;
  }
  /* .main-nav.smt-stick .navbar-nav a {
padding: 0 8px;
margin-left: 20px;
line-height: 58px;
}*/
  .login-side {
    display: none;
  }
  .login-content {
    width: 100%;
  }
  .login-form, .user-choose {
    width: 55%;
  }
  .login-page.fit-content {
    .login-form, .user-choose {
      width: 55%;
    }
  }
  .election-geo .center-tagline h3, .center-tagline h3 {
    margin-top: 20px;
  }
  .main-nav {
    .navbar-nav > li > a {
      padding: 0px 10px;
    }
    .nav.navbar-nav {
      float: right;
    }
    .navbar-brand > img {
      height: 42px;
      margin: 5px 0;
    }
  }
  .first-section, .second-section, .third-section {
    height: 320px;
  }
  .content-title {
    h3 {
      max-width: 90%;
    }
    h1 {
      margin-bottom: 10px;
    }
  }
  .city-official .smt-list .smd-item {
    width: 46%;
  }
  .right-box.has-icon .side-media {
    padding-left: 0;
    text-align: center;
  }
  .media-listing li > a {
    padding: 10px;
  }
  .side-icon {
    float: none;
    width: 35%;
    margin-right: 0;
    margin: 0 auto;
  }
  .right-box h3 {
    padding: 10px;
    font-size: 16px;
  }
  .masking-input {
    margin: 0 0 6px 0;
    min-width: 32.3%;
  }
  .feed-item {
    width: 48%;
  }
  .cities-inner {
    padding: 30px 0;
  }
  .people-detail {
    padding: 0;
  }
  .feat-insider .nav-tabs {
    li {
      width: 20%;
      min-width: auto;
    }
    > li {
      > a, &.active {
        min-height: 130px;
      }
    }
  }
  .action-description {
    line-height: inherit;
  }
  .home-action .action-button {
    display: block;
    float: none;
    margin: 10px auto 0;
    text-align: center;
  }
  .bottom-tabs .nav-tabs > li > a {
    padding: 14px 20px;
  }
  .top-cities-entry .btn-mobile {
    display: none;
  }
  .smt-list-entry.small {
    width: auto;
  }
  .people-ext {
    margin-top: 20px;
  }
  .cities-inner {
    padding: 85px 0 30px;
  }

  .pg-action:before {
    display: none;
  }

  .bottom-offer {
    padding: 10px 0;
  }

  .bottom-button {
    text-align: center;
  }

  .navbar-toggle {
    margin-right: 0;
  }

}

@media screen and (min-width: 693px) and (max-width: 990px) {
  .price-option h4 {
    min-height: 47px;
  }
}

@media screen and (max-width: 979px) {
  .main-nav .navbar-nav > li > a {
    padding: 0px 8px;
  }

  .company-misson .awward {
    margin: 30px auto;
  }

  .select2-container {
    width: 100%;
  }

}

@media screen and (max-width: 800px) {
  .main-nav .navbar-nav > li > a {
    padding: 0px 8px;
    font-size: 14px;
  }
  .feat-insider .nav-tabs > li > a {
    padding: 20px 6px;
  }
  .cities-inner {
    padding: 60px 0 0;
  }
  .people-detail {
    padding: 20px;
  }
  .bottom-tabs .nav-tabs > li > a {
    padding: 14px 15px;
  }


  .people-related {
    border-left: none;
    display: block;
    padding: 0;
    margin: 0;
  }

  .people-related .city-person.has-blue img,
  .people-related .city-person.has-red img{
    width: 100%;
  }

  .note-section .inner-title h3 {
    font-size: 125%;
  }

  .note-section .form-control {
    width: 95%;
    margin: 0 auto;
  }

  .search-form input[type="text"] {
    width: 100%;
  }

  .search-form .btn.btn-default {
    position: initial;
    margin: 20px auto 10px;
    width: 100%;
    background: #E4002B;
    color: #fff;
  }

  .search-form .btn.btn-default:hover {
    background: #fff;
    color: #E4002B;
    border-color: #E4002B;
  }

  .last-profile .center-tagline {
    padding: 0 0 20px;
  }

  .search-form input[type="text"] {
    width: 96%;
    margin: 15px auto 0;
  }

}

@media (min-width: 769px) and (max-width: 800px) {
  .select2-container {
    width: auto;
  }

}

@media screen and (max-width: 768px) {
  .gridCustom {
    display: none;
    &.is-actived {
      display: block;
    }
  }
  .btn-people-show {
    display: block;
  }
  .top-cities-entry {
    margin-top: 0;
    text-align: center;
  }
  .gridCustom .detail-city {
    margin-bottom: 10px;
  }
  .country-map .btn.btn-default.btn-map {
    display: none;
  }
  /*.top-cities-entry .btn-mobile {
      display: none;
  }
  .btn-people-show {
      display: none;
  }*/
  .main-nav .nav.navbar-nav {
    float: none;
  }
  .city-person {
    max-width: 25%;
    margin: 0 auto;
  }
  .people-description {
    text-align: center;
  }
  .personal-badge {
    margin-top: 20px;
  }
  .feat-insider .nav-tabs > li {
    i {
      font-size: 24px;
      padding-top: 10px;
    }
    > a, &.active {
      min-height: 100%;
      font-size: 13px;
      padding: 0;
      min-height: 90px;
    }
  }
  #pgAccount .login-mobile {
    font-size: 14px;
    font-weight: normal;
  }
  .btn-account {
    padding: 0 10px;
  }

  .city-official .smt-list .smd-item {
    width: 47%;
  }
  /*.city-person {
    max-width: 100%;
  }*/

  .tab-entry .mdi.mdi-information-variant {
    text-align: center;
    display: block;
  }

  .bottom-section .center-tagline {
    margin: 0 auto;
  }

  .top-cities-entry .btn-mobile {
    display: block;
  }

  .city-official .city-person {
    max-width: 100%;
  }
  .ted-table td:first-child:before {
    display: none;
  }


}

@media screen and (min-width: 639px) and (max-width: 766px) {
   .infeature-content .first-section .row,
   .infeature-content .second-section .row,
   .infeature-content .third-section .row{
     margin-top: 300px;
   }

  .infeature-content .second-section .row {
    margin-top: 235px;
  }

  .infeature-content .first-section {
    margin-bottom: 30px;
  }

  .infeature-content .first-section h3 {
    margin-top: 0;
  }

  .infeature-content .first-section h3,
  .infeature-content .second-section h3,
  .infeature-content .third-section h3,
  .infeature-content .fourtth-section h3{
    text-align: center;
  }


  .infeature-content .first-section,
  .infeature-content .second-section,
  .infeature-content .third-section {
    height: initial;
    background-position: top center;
  }

  .bottom-inner.more-padding .form-horizontal {
    position: relative;
    margin: 0 20px;
  }

  .bottom-inner.more-padding .form-control {
    width: 97%;
  }

  .top-inner .col-md-8.col-sm-6,
  .top-inner .col-md-4.col-sm-6 {
    width: 49%;
    display: inline-block;
    vertical-align: middle;
  }

  .city-person {
    max-width: 25%;
    margin: 0 auto;
  }
}


@media screen and (min-width: 641px) {
  .custom-arrow {
    display: none;
  }
}

@media screen and (max-width: 765px) {
  .search-mobile, .login-mobile, .faq-mobile {
    display: block;
  }
  .is-hide {
    display: none;
  }
  .bottom-tabs .nav-tabs > li > a {
    padding: 14px 10px;
  }

  .login-left, .login-right {
    font-size: 14px;
  }




}

@media screen and (max-width: 640px) {
  .tagline-content {
    h1 {
      font-size: 260%;
    }
    h3 {
      font-size: 110%;
      color: #f9f9f9;
      font-weight: 600;
      font-family: 'open sans', sans-serif;
      max-width: 90%;
      margin: 20px auto;
      line-height: normal;
      line-height: initial;
    }
    h1 {
      margin-bottom: 20px;
    }
    .btn.btn-outline {
      line-height: 46px;
    }
  }
  .center-tagline {
    margin: 35px auto;
    h3 {
      font-size: 190%;
    }
    h4 {
      max-width: 90%;
    }
  }
  .mid-section .page-title {
    text-align: center;
  }
  .business-feature .btn.btn-primary {
    display: block;
    max-width: 100%;
  }
  .home-action {
    .action-button {
      display: block;
      float: none;
      margin: 10px auto 0;
      text-align: center;
    }
    text-align: center;
  }
  .latest-update .tiled-items {
    width: 48%;
  }
  .bottom-offer, .bottom-button {
    padding: 20px;
    text-align: center;
  }
  .top-inner .btn.btn-red {
    float: none;
    text-align: center;
    margin: 0 auto;
    display: block;
    max-width: 35%;
  }
  .content-title h3 {
    &:after {
      display: none;
    }
    max-width: 90%;
    font-size: 120%;
  }
  .first-section, .second-section, .third-section {
    height: auto;
    background-position: top center;
  }
  .first-section h3, .second-section h3, .third-section h3 {
    margin-top: 230px;
    text-align: center;
  }
  .fourth-section h3 {
    text-align: center;
  }
  .big-title {
    margin: 0 85px;
  }
  .highlight-title {
    text-align: center;
  }
  .price-option .smtlist-item {
    width: 48%;
  }
  .smt-list .smtlist-item.second-line {
    min-width: 40%;
  }
  .plan-description .smtlist-item {
    padding: 0 5px;
  }
  .content-title h1 {
    font-size: 180%;
  }
  .middle-inner .smt-list.quarter .smt-quarter {
    width: 48%;
  }
  .company-misson {
    h3 {
      font-size: 130%;
    }
    .smt-list.half .smt-half {
      width: 100%;
    }
  }
  .awward {
    margin-bottom: 35px;
  }
  .login-form, .user-choose {
    width: 68%;
  }
  .login-page.fit-content {
    .login-form, .user-choose {
      width: 70%;
    }
  }
  .main-nav .navbar-brand > img {
    height: 48px;
  }
  .research-box .smt-list.third .smt-third, .demograph-content .smt-list.quarter .smt-quarter {
    width: 48%;
  }
  .main-nav .navbar-brand > img {
    height: 36px;
    margin: 6px 0;
  }
  .geo-content {
    display: block;
    .geo-list {
      display: inline-block;
      width: 48%;
      vertical-align: top;
    }
  }
  .latest-update .center-tagline h3 {
    margin-top: 20px;
  }
  .category-title:after {
    content: '';
    width: 65%;
  }
  .bottom-offer, .bottom-button {
    padding: 20px 0;
  }
  .top-inner {
    padding: 55px 0 20px;
    height: auto;
  }
  .main-nav .nav.navbar-nav {
    float: none;
  }
  .top-inner .breadcrumb {
    text-align: center;
  }
  .side-by-side.for-half > .side-block {
    display: block;
    width: 100%;
  }
  .entry-has-table {
    padding-left: 0;
    margin-left: 0;
    border-left: none;
  }
  .side-icon {
    width: 10%;
  }
  .city-person {
  max-width: 25%;
    margin: 55px auto;
  }
  .people-description {
    text-align: center;
    margin-bottom: 20px;
  }
  /*  .bottom-tabs .nav-tabs > li {
text-align: left;
min-width: 50%; }*/
  .city-person {

    margin: 0 auto;
    .person-badge {
      bottom: -5px;
    }
  }
  .detail-city {
    .top-detail, .country-map {
      min-width: 100%;
      margin-bottom: 20px;
    }
  }
  .top-cities-entry .page-title {
    margin-top: 10px;
  }
  .chevron-mobile {
    display: inline-block;
  }
  .bottom-tabs .nav-tabs {
    > li {
      text-align: left;
      min-width: 100%;
    }
    span {
      float: right;
    }
  }
  .search-form {
    input[type="text"] {
      max-width: 100%;
      margin: 0 auto;
    }
    .btn.btn-default {
      position: initial;
      margin: 20px 0 10px;
      display: block;
      width: 100%;
    }
  }
  .feat-insider .nav-tabs {
    li {
      min-width: 100%;
      text-align: left;
    }
    > li {
      i {
        display: inline-block;
        font-size: 24px;
        text-align: left;
        margin: 0 auto;
      }
      > a {
        padding: 0 20px;
        line-height: 44px;
      }
    }
  }
  .feat-short .btn.btn-primary {
    min-width: 46%;
    line-height: 34px;
    margin-top: 20px;
  }
  .center-tagline {
    margin: 10px auto;
  }
  .research-box .smt-list.third .smt-third {
    width: 100%;
  }
  .state-wide, .press-content {
    text-align: center;
  }
  .latest-update .tiled-items {
    width: 100%;
  }
  .geo-content .geo-list {
    display: block;
    width: 100%;
  }
  .feat-short {
    .btn.btn-primary {
      margin: 20px auto;
      display: block;
      max-width: 100%;
    }
    margin: 0 auto;
  }
  .bottom-tabs .nav-tabs > li.active > a {
    background: #fff;
    border-radius: 0;
    box-shadow: none;
    border: none;
    &:hover, &:focus {
      background: #fff;
      border-radius: 0;
      box-shadow: none;
      border: none;
    }
  }

 /* .city-person {
    max-width: 100%;
  }*/

  .detail-city .top-detail {
    padding: 0 20px;
  }

  .category-title:after {
    content: '';
    width: 80%;
  }

  .search-form input[type="text"] {
    width: 94%;
  }

  .second-section h3 {
    margin-top: 175px;
  }

  .first-section h3 {
    margin-top: 200px;
  }

  .fourth-section h3 {
    margin-top: 20px;
  }


}

@media screen and (max-width: 480px) {
  .tagline-content {
    h1 {
      font-size: 176%;
    }
    h3 {
      font-size: 85%;
      margin: 0 auto 20px;
    }
  }
  .center-tagline {
    h4 {
      font-size: 100%;
    }
    h3 {
      font-size: 135%;
    }
  }
  .business-feature .btn.btn-primary {
    display: block;
    max-width: 100%;
  }

  .feat-overview {
    .two-side {
      display: block;
    }
    .side-element.half {
      width: 100%;
      max-width: 100%;
    }
  }
  .latest-update .tiled-items, .top-inner .btn.btn-red {
    width: 100%;
    max-width: 100%;
  }
  .big-title {
    margin: 0 25px;
  }
  .more-padding {
    padding: 20px 0;
  }
  .grid-custom .smt-list.half .smt-half {
    width: 100%;
  }
  .login-form, .user-choose {
    width: 90%;
  }
  .login-page.fit-content {
    .login-left, .login-right {
      width: 100%;
    }
  }
  .research-box .smt-list.third .smt-third {
    width: 100%;
  }
  .chart-icon i {
    font-size: 75px;
  }
  .ted-table em {
    display: inline;
    display: initial;
  }
  .split-form.third-width {
    display: block;
    width: 100%;
    .split-item {
      width: 100%;
      display: block;
    }
  }
  .split-haspadding {
    padding: 5% 0;
    width: 100%;
  }
  .left-inner .smt-form .form-horizontal .control-label {
    text-align: center;
    display: block;
  }
  .masking-input {
    min-width: 48%;
  }
  .smt-form .inner-bottom.clean .btn.btn-default {
    min-width: 100%;
  }
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    padding: 0 5px;
  }
  .tab-content .inner-title {
    margin: 0 auto;
  }
  .people-related {
    padding: 0;
    .smt-list.half .smt-half {
      width: 100%;
      margin-bottom: 15px;
    }
  }
  .gridCustom.is-actived {
    padding: 0;
  }

  .login-left {
    margin-bottom: 20px;
  }

  .search-form input[type="text"] {
    width: 91%;
  }

  .plan-description .ted-table td i {
    display: initial;
  }

  .state-proportion .smt-list-item,
  .state-proportion .smt-list-entry,
  .state-proportion .side-block h3,
  .state-proportion .state-proportion-text h4 {
    display: block;
  }

  .state-proportion .side-block h3,
  .state-proportion .state-proportion-text h4 {
    text-align: center;
  }

  .category-title:after {
    content: '';
    width: 70%;
  }
}

@media screen and (max-width: 390px) {
  .price-option .smtlist-item {
    width: 100%;
  }
  .smt-list .smtlist-item.second-line {
    min-width: 0;
  }
  .plan-description .smtlist-item {
    padding: 0 2px;
  }
  .bottom-inner h3 {
    font-size: 95%;
  }
  .main-footer .col-xs-6 {
    width: 100%;
  }
  .content-title {
    h3 {
      font-size: 100%;
    }
    h1 {
      font-size: 120%;
      margin-bottom: 0;
    }
  }
  .contact-form .btn.btn-default {
    display: block;
    float: none;
    width: 100%;
    line-height: 34px;
  }
  .bottom-inner h3 {
    font-size: 120%;
  }
  .company-misson h3 {
    font-size: 115%;
    max-width: 90%;
    padding: 0;
  }
  .login-content, .login-side {
    height: auto;
  }
  .login-page {
    .login-form {
      margin-top: 55px;
    }
    &.fit-content {
      .login-form, .user-choose {
        width: 90%;
      }
    }
  }
  .feat-insider .nav-tabs li {
    min-width: 100%;
  }
  .feat-box {
    .feat-box-item {
      display: block;
    }
    &.half-of-one .feat-box-item, .feat-box-item {
      width: 100%;
    }
  }
  .feed-item {
    width: 100%;
  }
  .tagline-content h1 {
    line-height: inherit;
    margin: 20px 0;
  }
  .bottom-tabs .nav-tabs > li {
    min-width: 100%;
  }
  .nav-tabs > li.active > a {
    border: 1px solid transparent;
    &:hover, &:focus {
      border: 1px solid transparent;
    }
  }

  .city-official .smt-list .smd-item {
    width: 100%;
  }

}

@media (max-width: 320px) {
  .category-title:after {
    content: '';
    width: 60%;
  }
}
